import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import BreadcrumbAuto from "../breadcrumb/breadcrumb-auto";
import HeroChevron from "../hero/hero-chevron";
import SecondaryLanding from "../layouts/secondary-landing";
import SEO from "../seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import StretchedLinkCards from "../card/stretched-link-cards";
import BestBanksDefault from "../best-banks/best-banks-default";
import useCardBlogsData from "../../hooks/use-card-blogs-data";
import Button from "../custom-widgets/button";

export const query = graphql`
  query stateSmallBusinessQuery($slug: String) {
    imgVariableXXL: file(
      relativePath: { eq: "hero/about-us/state-small-business/hero-best-business-bank-013124-XXL.jpg" }
    ) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(
      relativePath: { eq: "hero/about-us/state-small-business/hero-best-business-bank-013124-XL.jpg" }
    ) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(
      relativePath: { eq: "hero/about-us/state-small-business/hero-best-business-bank-013124-LG.jpg" }
    ) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(
      relativePath: { eq: "hero/about-us/state-small-business/hero-best-business-bank-013124-MD.jpg" }
    ) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(
      relativePath: { eq: "hero/about-us/state-small-business/hero-best-business-bank-013124-SM.jpg" }
    ) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(
      relativePath: { eq: "hero/about-us/state-small-business/hero-best-business-bank-013124-XS.jpg" }
    ) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(
      relativePath: { eq: "hero/about-us/state-small-business/hero-best-business-bank-013124-XXS.jpg" }
    ) {
      ...heroChevronImageFragmentXXS
    }
    cardImage1: file(
      relativePath: { eq: "cards/state-small-business/thumbnail-account-that-works-for-you-013124.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    cardImage2: file(relativePath: { eq: "cards/state-small-business/thumbnail-small-business-credit-card.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    cardImage3: file(relativePath: { eq: "cards/state-small-business/thumbnail-help-your-business-grow-013124.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    twoColumnImage1: file(relativePath: { eq: "cards/state-small-business/thumbnail-business-services-013124.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    twoColumnImage2: file(
      relativePath: { eq: "cards/state-small-business/thumbnail-open-business-account-091523.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    twoColumnImage3: file(
      relativePath: { eq: "cards/state-small-business/thumbnail-open-business-bank-account-online-013124.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
      Description
      StateGeoLocation {
        Lat
        Lng
        Zoom
      }
      branch_locations {
        PageURL
        Address {
          City
          Country
          State
          StreetAddress
          ZipCode
        }
      }
      FeaturedBranches {
        Active
        DisplayOrder
        Title
        Subtitle
        branch_location {
          Title
          MSBookingsBranchName
          PageURL
        }
      }
      FeaturedReviews {
        Active
        DisplayOrder
        AuthorsName
        ReviewText
        ReviewTitle
        branch_location {
          PageURL
        }
      }
      SEO {
        MetaDescription
        MetaTitle
      }
    }
  }
`;

const StateSmallBusinessTemplate = ({ location, data }) => {
  const stateData = data.strapiBranchState;
  const StateName = stateData.StateName;
  const StateCode = stateData.StateCode;

  const pageLocation = { location };

  const heroChevronData = {
    id: "state-branches-hero",
    ...getHeroImgVariables(data),
    altText: "Young entrepreneur on his mobile phone at the checkout counter in his shop.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Your Business Deserves a Best Bank"
          }
        },
        {
          id: 2,
          button: {
            id: "state-branches-hero-cta",
            text: "Open an Account",
            url: "/business-banking/open-business-bank-account-online",
            class: "btn-white",
            containerClass: "mb-3"
          }
        }
      ]
    }
  };

  const metaTitle = `Best Business Bank in ${StateName}`;
  const metaDescription = `Discover the top business bank in ${StateName}. WaFd Bank is a small business lender offering tailored business accounts. Open a business bank account today!`;
  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:url",
        content:
          "https://www.wafdbank.com/about-us/business-banking/" +
          (stateData.Slug === "washington" ? "washington-state" : stateData.Slug)
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-best-business-bank-013124.jpg"
      }
    ]
  };

  const topCardsData = {
    sectionId: "state-small-business-top-cards",
    sectionClass: "text-left py-0",
    rowColsClass: "row-cols-1 row-cols-md-3",
    title: "",
    titleClass: "text-center",
    hasGrowEffect: false,
    hasRiseShadowEffect: true,
    useMobileView: false,
    cards: [
      {
        id: "mb-card-1",
        url: "/business-banking/business-checking-account",
        image: {
          imgVariable: data.cardImage1.childImageSharp.gatsbyImageData,
          altText: "A businessman using a tablet."
        },
        title: "Get an Account That Works For You",
        titleClass: "text-success",
        text: "All options come with business account online banking and the all the tools you need to get things done.",
        showTextArrow: true
      },
      {
        id: "mb-card-2",
        url: "/business-banking/small-business-credit-card",
        image: {
          imgVariable: data.cardImage2.childImageSharp.gatsbyImageData,
          altText: "Businesswoman using a WaFd credit card for online shopping."
        },
        title: "Business Credit Cards",
        titleClass: "text-success",
        text: "Get cash back on purchases, plus flexibility and convenience. Our card options are designed for business owners like you!",
        showTextArrow: true
      },
      {
        id: "mb-card-3",
        url: "/business-banking/business-line-of-credit",
        image: {
          imgVariable: data.cardImage3.childImageSharp.gatsbyImageData,
          altText: "A businessman in his woodworking shop on a mobile phone and using his laptop."
        },
        title: "WaFd is Here to Help Your Business Grow",
        titleClass: "text-success",
        text: "From business lines of credit to commercial real estate financing, WaFd has options to fit your needs.",
        showTextArrow: true
      }
    ]
  };

  const blogCards = useCardBlogsData([
    "/blog/small-business/small-business-cybersecurity-tips",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business",
    "/blog/small-business/how-to-grow-small-business"
  ]);

  const blogCardsData = {
    sectionId: "state-small-business-blog-cards",
    sectionClass: "text-left pb-2 py-4",
    rowColsClass: "row-cols-1 row-cols-md-3",
    title: "",
    titleClass: "text-center",
    hasGrowEffect: false,
    hasRiseShadowEffect: true,
    useMobileView: false,
    cards: blogCards?.map((card, i) => ({
      title: card.title,
      image: {
        imgVariable: card.imgVariable,
        altText: card.imageAlt,
        imgSrc: card.imageUrl
      },
      url: card.slug,
      id: `blog-link-cards-card-${i + 1}`,
      isInBlog: false
    }))
  };

  const branchesByState = {
    AZ: [
      {
        name: "Prescott Valley",
        url: "/locations/arizona/prescott-valley"
      },
      {
        name: "Yuma",
        url: "/locations/arizona/yuma"
      },
      {
        name: "Douglas",
        url: "/locations/arizona/douglas"
      },
      {
        name: "Show Low",
        url: "/locations/arizona/show-low"
      }
    ],
    CA: [
      {
        name: "Beverly Hills",
        url: "/locations/california/beverly-hills"
      },
      {
        name: "Los Altos",
        url: "/locations/california/los-altos"
      },
      {
        name: "Pasadena",
        url: "/locations/california/pasadena"
      },
      {
        name: "Santa Rosa",
        url: "/locations/california/santa-rosa"
      }
    ],
    ID: [
      {
        name: "Sandpoint",
        url: "/locations/idaho/sandpoint"
      },
      {
        name: "Rexburg",
        url: "/locations/idaho/rexburg"
      },
      {
        name: "Twin Falls",
        url: "/locations/idaho/twin-falls"
      },
      {
        name: "McCall",
        url: "/locations/idaho/mccall"
      }
    ],
    UT: [
      {
        name: "Salt Lake City",
        url: "/locations/utah/salt-lake-city"
      },
      {
        name: "Draper",
        url: "/locations/utah/draper"
      },
      {
        name: "Logan",
        url: "/locations/utah/logan"
      },
      {
        name: "Price",
        url: "/locations/utah/price"
      }
    ],
    NM: [
      {
        name: "Farmington",
        url: "/locations/new-mexico/farmington"
      },
      {
        name: "Silver City",
        url: "/locations/new-mexico/silver-city"
      },
      {
        name: "Hobbs",
        url: "/locations/new-mexico/hobbs"
      },
      {
        name: "Clovis",
        url: "/locations/new-mexico/clovis"
      }
    ],
    NV: [
      {
        name: "Las Vegas",
        url: "/locations/nevada/las-vegas"
      },
      {
        name: "Ely",
        url: "/locations/nevada/ely"
      },
      {
        name: "Fernley",
        url: "/locations/nevada/fernley"
      },
      {
        name: "Elko",
        url: "/locations/nevada/elko"
      }
    ],
    OR: [
      {
        name: "Hillsboro",
        url: "/locations/oregon/hillsboro"
      },
      {
        name: "Cave Junction",
        url: "/locations/oregon/cave-junction"
      },
      {
        name: "Lakeview",
        url: "/locations/oregon/lakeview"
      },
      {
        name: "Hood River",
        url: "/locations/oregon/hood-river"
      }
    ],
    TX: [
      {
        name: "Plano",
        url: "/locations/texas/plano"
      },
      {
        name: "Austin",
        url: "/locations/texas/austin"
      }
    ],
    WA: [
      {
        name: "Vancouver",
        url: "/locations/washington/vancouver"
      },
      {
        name: "Walla Walla",
        url: "/locations/washington/walla-walla"
      },
      {
        name: "Colville",
        url: "/locations/washington/colville"
      },
      {
        name: "Blaine",
        url: "/locations/washington/blaine"
      }
    ]
  };

  const branches = branchesByState[StateCode]
    ? branchesByState[StateCode].map((branch) => (
        <Link to={branch.url} id={`branch-${branch.name.toLowerCase().replaceAll(" ", "-")}-link`}>
          {branch.name}
        </Link>
      ))
    : [];

  const getBranches = () => {
    switch (branches.length) {
      case 2:
        return (
          <>
            {branches[0]} to {branches[1]}
          </>
        );
      case 4:
        return (
          <>
            {branches[0]} to {branches[1]}, {branches[2]} to {branches[3]} and just about everywhere in between,
          </>
        );
      default:
        // We decided to add a default case with the first and last items on the array. This also logs an error to the console.
        console.error(
          `UNHANDLED CASE: state-small-business for ${StateName} has length ${branches.length} for branches. This value should be 2 or 4, or it should be a new case.`
        );

        return (
          <>
            {branches[0]} to {branches[-1]}
          </>
        );
    }
  };

  // These are the BLOG_STATE_SLUG parts of /blog/small-business/BLOG_STATE_SLUG-business-bank-account-requirements
  const blogStateSlug = {
    AZ: "arizona",
    CA: "california",
    ID: "idaho",
    UT: "utah",
    NM: "new-mexico",
    NV: "nevada",
    OR: "oregon",
    TX: "texas",
    WA: "washington"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <HeroChevron {...heroChevronData} />
      <BreadcrumbAuto {...pageLocation} />
      <SEO {...SEOData} />
      <div id="state-small-business-wrapper">
        <section id="state-small-business-overview-section" className="container pb-4">
          <h1 className="mb-3">Best Business Bank in {StateName}</h1>
          <h3 className="mb-0">
            WaFd Bank has won Best Bank awards in both the Regional Bank and Employer categories every year since 2017,
            so you know you'll have a great partner to help you grow your business. Wherever your business is located in
            the state of {StateName}, WaFd is there for you. With branches from {getBranches()} you'll be able to get
            your banking done and get back to running your business.
          </h3>
        </section>
        <StretchedLinkCards {...topCardsData} />
        {/** We are removing the Two Column component, help me make the columns manually */}
        <section className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <GatsbyImage
                  image={data.twoColumnImage1.childImageSharp.gatsbyImageData}
                  alt="A businesswoman taking inventory in the warehouse on a tablet."
                  className="mb-4 mb-lg-0"
                />
              </div>
              <div className="col-lg-6">
                <h2 className="text-success">{StateName} Business Services</h2>
                <p>
                  WaFd Bank is here to help you manage and grow your business. Whatever your business needs are WaFd has{" "}
                  <Link to="/business-banking/business-services" id="state-small-business-business-services-link">
                    business services
                  </Link>{" "}
                  to help you get back to doing what you do best! Find solutions for point of sale and inventory
                  systems, payment processing services, accounting automation, and even getting your invoices paid in
                  hours vs weeks with CollectEarly™, find ways to streamline your processes and increase productivity.
                </p>
                <div className="text-right">
                  <Button
                    url="/business-banking/business-services"
                    id="state-small-business-services-learn-more-button"
                    text="Learn More"
                    type="link"
                    icon={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="text-success">What is Needed to Open a Business Bank Account in {StateName}</h2>
              <p className="mb-lg-0">
                Starting a business bank account doesn't have to be complicated! The documents needed to open an account
                depend on your business structure, such as LLC or sole proprietorship, but typically you need to provide
                your EIN or SSN, the legal name of your business and any DBAs (doing business as) if different from the
                legal name of your business. Other than that, you'll need your own ID, any formation documents, and a
                printout from the Secretary of State verifying your business is active. You can read more in our article{" "}
                <Link
                  to={`/blog/small-business/${blogStateSlug[StateCode]}-business-bank-account-requirements`}
                  id="state-what-is-needed-blog-links"
                >
                  What is Needed to Open a Business Bank Account in {StateName}
                </Link>
                .
              </p>
            </div>
            <div className="col-lg-6">
              <GatsbyImage
                image={data.twoColumnImage2.childImageSharp.gatsbyImageData}
                alt="Businesswoman typing order while on phone with a client."
                className="mb-4 mb-lg-0"
              />
            </div>
          </div>
        </section>
        <section className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <GatsbyImage
                  image={data.twoColumnImage3.childImageSharp.gatsbyImageData}
                  alt="A businessman at his desk working on a tablet."
                  className="mb-4 mb-lg-0"
                />
              </div>
              <div className="col-lg-6">
                <h2 className="text-success">Open a Business Bank Account Online</h2>
                <p>
                  We've designed our accounts to work for you, whether you're looking for business checking or savings
                  accounts we also have add-on services you can use to continue to grow your business. After opening
                  your accounts, you can upgrade with Remote Deposit Capture, wires, and ACH. Come see for yourself the
                  difference WaFd can make for you!
                </p>
                <div className="text-right">
                  <Link
                    to="/business-banking/open-business-bank-account-online"
                    id="open-account-cta"
                    className="btn btn-primary"
                  >
                    Open an Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <StretchedLinkCards {...blogCardsData} />
        <BestBanksDefault />
      </div>
    </SecondaryLanding>
  );
};

export default StateSmallBusinessTemplate;
